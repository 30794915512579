import React from "react"
import { Button, Layout } from 'antd'
import SiteLayout from "../components/SiteLayout";


//@ts-ignore 
import styles from './kjopsvilkar.module.scss';
import YourData from "../components/Sections/YourData";


export default ({ children }) => (
    <SiteLayout>
        <YourData />
<div className={styles.smallcontainer}>
<h1>Generelt</h1>
<p>Salgsbetingelsene utgjør sammen med din bestilling, bekreftet gjennom en ordrebekreftelse, det samlede avtalegrunnlaget for kjøpet. Salgsbetingelsene og annen informasjon på www.oss.no er bare tilgjengelig på norsk. 
<br/>
For å kunne handle på www.oss.no må du ha fylt 18 år.
<br/><br/>
<b>Der det i disse salgsbetingelsene stilles krav til skriftlighet, er dette oppfylt ved bruk av e-post, SMS, brev, eller lignende.</b>
</p>
<h2>1. Parter</h2>
<p>«Selger» er: OSS NORGE AS, , hei@oss.no<br/>
Informasjon om Oss Norge AS:<br/>
- Org.nr. 919 439 734<br/>
- Hovedkontorets adresse: Grønland 67, 3045 Drammen<br/>
- E-post: hei@oss.no.<br/>
«Selger» blir i det følgende benevnt «vi», «oss» eller «Selger». «Kjøper» er: den person som er oppgitt som kjøper i bestillingen, og blir i det følgende benevnt «du», «deg», «din» eller «ditt».<br/>
</p>

<h2>2. Din bestilling</h2>
<p>Når vi mottar din bestilling, vil vi bekrefte ordren og automatisk sende en ordrebekreftelse til deg på e-postadressen du har oppgitt. Les nøye gjennom ordrebekreftelsen når du mottar den, og undersøk om ordrebekreftelsen er i overensstemmelse med bestillingen.</p>
<p>Avvik mellom din bestilling og ordrebekreftelsen anses som et nytt tilbud fra oss, som du kan akseptere eller takke nei til. Dersom du har bestilt mer enn ett produkt kan det hende at du får produktene i separate forsendelser.</p>
<p>Vi forbeholder oss retten til å kansellere din bestilling eller del av denne, dersom produktet er utsolgt. Ved utsolgtsituasjoner vil du få melding om dette, eventuelt sammen med informasjon om hva vi kan tilby i stedet. Du vil få muligheten til å akseptere vårt nye tilbud med de endringer vi angir i forhold til din bestilling, eller kansellere bestillingen. Videre tas det forbehold om kansellering av ordrer beregnet for videresalg.</p>
<p>Dersom det har forekommet skrive- eller trykkfeil av vesentlig størrelse fra Selger sin side, i annonser o.l. og/eller i nettbutikken som er større enn 15% av normal utsalgspris, kan Selger ensidig endre, slette eller avvise ordre. Dette gjelder selv om kunde har mottatt ordrebekreftelse eller lignende.</p>
<p>Vi har mulighet til å gjøre endringer frem til varen sendes fra vårt lager. Dersom varen er sendt fra vårt lager må du benytte angreretten din.</p>

<h2>3. Betaling</h2>
<p>Ved spørsmål om betaling kan du kontakte Oss Norge AS per e-post, hei@oss.no</p>
<p>Alle priser er inkludert merverdiavgift. Ordreoversikten vil inkludere alle utgifter forbundet med varen som utgifter til porto, frakt, emballasje m.m.</p>
<p>Som betalingsmiddel kan du velge blant de betalingsmetoder vi tilbyr i kjøpsprosessen. Når vi har mottatt din bestilling, foretar vi en reservasjon på ditt betalingsmiddel for å sikre at det finnes tilstrekkelig midler til å dekke kjøpesummen og fullføre transaksjonen. Hvis reservasjon ikke kan foretas, forbeholder vi oss retten til å kansellere bestillingen. Du vil motta informasjon om slik eventuell kansellering.</p>
<p>Hvis du bruker betalingskort, PayPal eller direktebank som betalingsmetode, blir du belastet når vi bekrefter din ordre og levering er iverksatt hos oss. Dette gjelder også ved forhåndsbestilling av produkter som blir tilgjengelig fram i tid eller har en ubekreftet leveringsdato.</p>
<p>For abonnementskjøp der en avtalt sum skal trekkes for eksempel månedlig, vil dine betalingsdetaljer lagres kryptert og sikkert hos vår betalingsformidler og kunne brukes av vår leverandør til å gjennomføre transaksjonen. Samtykke til dette innhentes også fra deg i kjøpsprosessen.</p>
<p>Har du et abonnement hos oss og den månedlige betalingen ikke går igjennom på betalingsdato, vil vi maksimalt prøve 4 ganger i løpet av 14 dager før vi kansellerer ditt abonnement, og tjenestene til opphøre.</p>

<h2>4. Levering og forsinkelse</h2>
<p>Levering av produktene skjer på den måten som er angitt på ordrebekreftelsen. Vi har risikoen for produktene inntil de overtas av deg, det vil si når du har fått produktene i din besittelse.</p>
<p>Dersom leveringen av produktene blir forsinket, vil vi gi deg informasjon så fort vi har kunnskap om det, sammen med informasjon om, og eventuelt når, levering kan skje, eller om produktet(ene) er utsolgt. Vi gir deg valget mellom å opprettholde ordren, fastsette ny leveringsdato eller kansellere ordren med tilbakebetaling av innbetalt beløp.</p>
<p>Kjøper er selv ansvarlig for åpning av Home Area Network-port. Det gjøres via kjøpers nettleverandør.</p>
<p>Dersom du har bestilt mer enn ett produkt kan det hende at du får produktene i separate forsendelser.</p>

<h2>5. Reklamasjon</h2>
<p>Etter at du har mottatt produktene, anbefaler vi at du så snart som mulig undersøker om leveransen er i samsvar med ordrebekreftelsen, om produktene er blitt skadet under transporten, eller om produktene ellers har feil eller mangler.</p>

<p>Dersom produktene har feil eller mangler kan du etter omstendighetene kreve retting av feilen, omlevering, prisavslag, erstatning eller at kjøpet heves. Selger kan kreve å få reparere varen hvis det er umulig å skaffe en ny, for eksempel hvis varen ikke lenger blir produsert, eller at det medfører en urimelig kostnad. Melding om feil og mangler ved produktene kan overbringes skriftlig. Dette må skje innen rimelig tid etter at du oppdaget feilen eller mangelen. Vi anbefaler av bevishensyn at reklamasjonen sendes oss skriftlig. Retten til å reklamere gjelder i to år etter at du overtok produktet, eller fem år dersom produktet er ment å vare vesentlig mer enn to år. Reklamasjon må rettes mot selger av det aktuelle produkt dvs Oss Norge AS</p>

<h2>6. Angrerett</h2>
<p>Angrerettloven gir deg rett til å angre ditt kjøp. Angreretten gjelder for varer og for enkelte tjenester. Angreretten forutsetter at du senest innen 14 dager etter at du mottar leveransen gir selger beskjed om at du vil benytte angreretten, primært ved å benytte mottatt angrerettskjema, eller skriftlig per brev, e-post, SMS med referanse til ordrenummer.</p>

<p>Dersom du ikke har mottatt angrerettskjema enten i ordrebekreftelsen eller ved levering av produktene, utvides denne fristen til tolv måneder etter at varen er mottatt.</p>

<p>For at angreretten skal kunne gjøres gjeldende, må produktet være levert oss i tilnærmet samme mengde og stand som du mottok den. Produktet skal returneres innen 14 dager fra den datoen vi fikk melding om at du gjorde angreretten gjeldene. Eventuell returforsendelse må betales av deg.</p>

<p>Selger er forpliktet til å betale tilbake det du har betalt, inkludert porto for forsendelsen, ekspedisjonsgebyr, tollavgifter, oppkravsgebyr, etc. Tilbakebetaling skal finne sted innen fjorten dager etter at selger mottar produktet fra deg eller henteseddel eller produktet er stilt til rådighet for selger.</p>


<p>Produktet sendes tilbake til oss (om mulig med sporingsnummer), om mulig i originalemballasjen, sammen med angrerettskjema (som ligger vedlagt på ordrebekreftelsen du fikk på e-post). Skjemaet kan lastes ned her.</p>


<p>Ved kjøp fra Oss Norge AS kan varen returneres her:
<br/>
Oss Norge AS<br/>
Grønland 67<br/>
3045 Drammen</p>

<h2>7. Personopplysninger</h2>
<p>Personvernerklæring for Oss kan leses <a href="https://www.oss.no/personvern" target="_BLANK">her</a></p>
<h2>8. Tvister</h2>
<p>Partene skal forsøke å løse eventuelle tvister i minnelighet. Dersom dette ikke lykkes, kan du bringe saken inn for Forbrukerrådet i nærheten av der du bor. Part i saken er Selger, dvs Oss Norge AS. Alle tvister skal løses etter norsk rett. Bringes saken inn for domstolene, skal den avgjøres i ditt (kjøperens) verneting, det vil normalt si i nærheten av der du bor.</p>
<h2>9. Abonnement</h2>
<p>Om du har kjøpt et abonnement eller et produkt med abonnement godkjenner du Selgers vilkår. Ved å godkjenne denne avtalen godkjenner du også at det blir foretatt en kredittsjekk av deg og at vi kan kansellere salget dersom du ikke får godkjent kredittsjekk fra leverandøren. </p>

<p>Man må være fylt 18 år for å kunne bestille et abonnement.</p>

<h2>10. Dekning</h2>
<p>Enkelte av produktene til Selger er avhengig av mobildatadekning for å kunne levere tjenester. Dersom produktet ikke har tilstrekkelig med dekning etter oppkobling har Kjøper rett til å heve kjøpet innen 30 dager etter mottatt produkt. Innbetalt beløp refunderes etter vi har mottatt produktet i retur. </p>

<h2>11. Endringer i avtalen</h2>
<p>Vi ønsker å tilby en tjeneste som er best mulig og tilpasset brukerens behov. Oss er derfor i stadig utvikling både når det gjelder produkter og tjenester. Dersom vi gjør endringer i pris - og/eller produktstruktur vil vi varsle deg 14 dager før endringene trer i kraft.Dersom du ikke aksepterer nye og/eller endrede vilkår, kan du si opp din avtale hos oss. Du vil til enhver tid finne vilkårene på vår hjemmeside.</p>

<h2>12. Varighet og oppsigelse</h2>
<p>Du kan si opp avtaleforholdet ditt med en måneds varsel. Oppsigelsesfristen regnes fra den første dagen i den påfølgende måneden etter oppsigelsen. Oss Norge AS kan med øyeblikkelig virking si opp avtalen ved vesentlig mislighold fra kundensside. Kunden vil bli varselet på e-post om grunnlaget for oppsigelse.</p>

</div>
    </SiteLayout>
)
